<template>
  <div>
    <sub-head v-bind:subTitle="content['설치 사례']"></sub-head>
    <div class="page_padding">
      <div class="paddingTB">
        <div class="text-center header">
          <div>
            <span class="fontBold font41 word_break text333"
              >치매 안심센터 및 전시회 등을 통한<br />
              <span class="fontBold font41 textPink"
                >실제 사용자 및 이용자에 대한 피드백</span
              ></span
            >
          </div>
          <!-- <div>
            <span class="fontBold font41 textPink"
              >실제 사용자 및 이용자에 대한 피드백</span
            >
          </div> -->
          <div class="verticalSpacer-half"></div>
          <div class="text-center">
            <span class="word_break font18 fontMedium">
              치매 안심센터 및 요양원, 전시회 등을 통한 다양한 이해 관계자에
              대한 피드백을 통하여 콘텐츠 고도화 및 상용화를 위한 준비를
              진행하고 있습니다. 실제 사용자를 대상으로 사용성 검증 및 효과성
              검증뿐만 아니라, 재미 측면에 대한 확인을 통해서 노인 전문 콘텐츠를
              제공해 드리기 위하여 진행 중에 있습니다.
            </span>
          </div>
          <div class="verticalSpacer-half"></div>
        </div>
        <div class="reference">
          <div
            v-for="(item, info) in reference_info"
            :class="[
              {'margin-right': info % 2 == 0},
              {'margin-left': info % 2 == 1},
            ]"
            :key="info"
            class="d-flex"
          >
            <div>
              <div class="imgWrap">
                <img :src="getImgSrc(item.img_src)" />
              </div>
              <div class="text-left paddingTB1">
                <div class="headlineWrap">
                  <span class="fontExtraBold font20 text333">{{
                    item.headline
                  }}</span>
                </div>
                <div class="contentWrap">
                  <span class="fontS">{{ item.content }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/happytable/more" class="btn">
          더 많은 설치 사례 보기
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import jsonContents from "../../assets/source/json/sub_top.json";
import subHead from "../../components/sub_head.vue";
export default {
  components: {subHead},
  data() {
    return {
      content: jsonContents,
      reference_info: [
        {
          headline: "국민 건강 보험 공단 - 서울요양원",
          content: "'해피테이블' 치매예방 프로그램 운영",
          img_src: "reference01",
        },
        {
          headline: "성남시 중원구 치매 안심센터 / 한국 치매 예방 강사 협회",
          content: "업무 협약 체결 및 프로그램 운영",
          img_src: "reference02",
        },
        {
          headline: "한컴 말랑말랑 데이케어센터 / 아리아케어 데이케어센터",
          content: "기업형 노인보호 시설 시범운영",
          img_src: "reference03",
        },
        {
          headline: "용인시 기흥 노인 복지관",
          content: "'해피테이블' 프로그램 운영",
          img_src: "reference04",
        },
        {
          headline: "강남대학교 미래복지 융복합연구소",
          content: "사회복지학과 학생 실습 및 활용 연구",
          img_src: "reference05",
        },
        {
          headline: "성남 위례종합사회복지관",
          content: "'해피테이블' 프로그램 운영",
          img_src: "reference06",
        },
      ],
      show: false,
      is_all: "",
      button_value: "더보기",
    };
  },
  methods: {
    getImgSrc(src) {
      var images = require.context(
        "../../assets/source/images/",
        false,
        /\.jpg$/
      );
      return images("./" + src + ".jpg");
    },
    is_show() {
      if (this.show == false) {
        // 전체 보기
        this.show = true;
        this.is_all = "all";
        this.button_value = "접기";
      } else {
        // 상위만 보기
        this.show = false;
        this.is_all = "";
        this.button_value = "더보기";
      }
    },
    filtering_reference: function(content) {
      if (content == "all") {
        return this.reference_info;
      } else {
        return this.reference_info.filter(function(element, index) {
          return index < 4;
        });
      }
    },
  },
};
</script>

<style scoped>
.reference {
  padding: 3rem 0;
  display: flex;
  flex-wrap: wrap;
}
.reference .d-flex {
  width: 49%;
  justify-content: center;
  padding: 1rem 0;
}
.reference img {
  border-radius: 20px;
}
.imgWrap {
  width: 100%;
}
.imgWrap img {
  width: 100%;
}
.d-flex div {
  width: 100%;
}
.margin-right {
  margin-right: auto;
}
.margin-left {
  margin-left: auto;
}
.header {
  border-bottom: 1px solid #d1d1d1;
}
.btn {
  color: black !important;
  border: none;
  text-decoration: none;
  background-color: #e3e3e3;
  outline: none;
  margin: 0 auto;
  width: 100%;
  height: 100px;
}
.btn:hover {
  background-color: #e3e3e3;
}
.headlineWrap {
  padding-bottom: 0.2rem;
}
.contentWrap {
  color: #606060;
}
@media (max-width: 767px) {
  .reference {
    flex-direction: column;
    padding: 1.5rem 0;
  }
  .reference .d-flex {
    width: 100%;
    padding: 0.5rem 0;
  }
  .btn {
    height: 80px;
  }
}
</style>
